import { useQuery } from '@apollo/client'
import Spinner from 'components/feedback/Spinner'
import { groupEssentialsQuery } from 'lib/queries'
import { useIntl } from 'react-intl'

const Group = ({ children, groupId, query, Loading }) => {
  const { locale } = useIntl()

  const { loading, error, data } = useQuery(query || groupEssentialsQuery, {
    variables: { id: groupId, locale },
    skip: !groupId,
  })

  if (loading || !groupId) {
    if (Loading) {
      return <Loading />
    } else {
      return (
        <div className="flex justify-center items-center h-full m-4">
          <Spinner />
        </div>
      )
    }
  }

  if (error) {
    console.error(error)
    return null
  }

  if (!data.group) return <div>Not found</div>

  return children(data)
}

export default Group
