import { Page } from '../Pagination'

const formatPagesIntoArray = (
  currentPage: number,
  totalPages: number
): Page[] => {
  // first one
  const pages: Page[] = [
    {
      selected: !currentPage,
      pageNumber: 0,
    },
  ]

  // first three dots
  if (currentPage >= 3) {
    pages.push({
      threeDots: true,
      pageNumber: currentPage - 2,
    })
  }

  // everything in between
  if (totalPages > 2) {
    if (currentPage - 1 > 0) {
      pages.push({
        selected: false,
        pageNumber: currentPage - 1,
      })
    }

    if (currentPage > 0 && currentPage < totalPages - 1) {
      pages.push({
        selected: true,
        pageNumber: currentPage,
      })
    }

    if (currentPage + 1 < totalPages - 1) {
      pages.push({
        selected: false,
        pageNumber: currentPage + 1,
      })
    }
  }

  // last three dots
  if (totalPages > 3 && currentPage < totalPages - 3) {
    pages.push({
      threeDots: true,
      pageNumber: currentPage + 2,
    })
  }

  // last one
  if (totalPages > 1) {
    pages.push({
      selected: currentPage === totalPages - 1,
      pageNumber: totalPages - 1,
    })
  }

  return pages
}

export default formatPagesIntoArray
