import { useCurrentGroup } from 'lib/GroupContext'
import { MapPin } from 'react-feather'

const Location = ({ children }) => {
  const { currentGroup } = useCurrentGroup()

  const color =
    currentGroup && currentGroup.styles && currentGroup.styles.accentColor

  return (
    <div className="justify-center">
      <MapPin size={13} className="inline mb-1" color={color || 'purple'} />
      &nbsp;
      {children}
    </div>
  )
}

export default Location
