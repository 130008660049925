import classNames from 'classnames'
import Location from 'components/display/Location'
import ProfilePicture from 'components/display/ProfilePicture'
import { useCurrentGroup } from 'lib/GroupContext'
import { memberUrl } from 'lib/urls'
import Link from 'next/link'
import { ArrowRight } from 'react-feather'
import { useIntl } from 'react-intl'

// @TODO: add to storybook
const MemberCard = ({ user, className, showFull, showCompact }) => {
  const { currentGroup } = useCurrentGroup()
  const { formatMessage, locale } = useIntl()

  if (!currentGroup) return null

  return (
    <Link
      href={`/${locale}${memberUrl(currentGroup, user?.slug || user.id, user)}`}
    >
      <a className={className}>
        <div
          className={classNames(
            'relative flex flex-col',
            'bg-white text-black text-center',
            'justify-evenly rounded overflow-hidden h-full break-words',
            'p-1/12'
          )}
        >
          <div className="flex-0 w-10/12 mx-auto pt-2">
            <ProfilePicture user={user} className="w-11/12 mx-auto" asAvatar />
            <div
              className={classNames(
                'mt-6 font-bold',
                showFull ? 'text-xl' : 'text-lg lg:text-xl'
              )}
            >
              {user.name}
            </div>
            <div className="h-auto">
              {user.disciplines.map((d) => d.name).join(', ') || <>&nbsp;</>}
            </div>
          </div>

          <span className="flex flex-1 flex-col justify-evenly space-y-6">
            <div className="flex flex-1 justify-center items-center italic">
              {user && user.role}
            </div>
            {user.location && <Location>{user.location}</Location>}
          </span>

          <div className="mt-6 pb-2 mx-auto flex justify-center flex-0">
            <div
              className={classNames(
                'flex items-center whitespace-nowrap border-2 border-black rounded-full',
                showFull && 'space-x-2 px-4 py-2',
                !showCompact && 'lg:space-x-2 lg:px-4 lg:py-2',
                'p-1 mt-2'
              )}
            >
              <p
                className={classNames(
                  showFull && 'block',
                  showCompact && 'hidden',
                  !showFull && !showCompact && 'hidden lg:block',
                  'whitespace-nowrap'
                )}
              >
                {formatMessage({ id: 'menu.viewProfile' })}
              </p>
              <ArrowRight size={16} />
            </div>
          </div>
        </div>
      </a>
    </Link>
  )
}

export default MemberCard
