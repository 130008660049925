import classNames from 'classnames'
import Pill from 'components/display/Pill'
import Input from 'components/Input'
import { useEffect, useState } from 'react'
import { Search as SearchIcon, X } from 'react-feather'
import { useIntl } from 'react-intl'

// @TODO: enable setTimeout and have search perform automatically
// after users pauses in typing
const Search = ({
  className,
  query,
  placeholder,
  setQuery,
  setPage,
  collapsed,
}) => {
  const [value, setValue] = useState(query)
  const [expanded, setExpanded] = useState(!collapsed)

  useEffect(() => {
    setValue(query)
  }, [query])

  const performSearch = (value) => {
    setValue(null)
    setQuery(value)
    setExpanded(!collapsed)
  }

  const onChange = (e) => {
    setValue(e.target.value)
  }

  const onKeyPress = (e) => {
    if (e.key === 'Enter') {
      setQuery(value ? value : undefined)
      if (setPage) {
        setPage(undefined)
      }
    }
  }

  const onCancelClick = () => {
    setValue(undefined)
    if (query) {
      setQuery(undefined)
      if (setPage) {
        setPage(undefined)
      }
    }
  }

  const { formatMessage } = useIntl()

  return (
    <div
      className={classNames('w-full relative', !expanded && 'flex', className)}
    >
      {expanded ? (
        <Input
          placeholder={placeholder || formatMessage({ id: 'term.search' })}
          value={value || ''}
          onChange={onChange}
          onKeyPress={onKeyPress}
          border
        />
      ) : (
        query && (
          <Pill
            className="mx-2 order-2 flex space-x-1 items-center"
            color="gray"
          >
            <p>{query}</p>
            <Close onClick={onCancelClick} className="w-3 h-3" />
          </Pill>
        )
      )}
      <div
        className={`${
          expanded ? 'absolute right-0 inset-y-0 mr-4' : 'relative'
        } flex space-x-2`}
      >
        {expanded && (query || value) && <Close onClick={onCancelClick} />}
        <button
          onClick={() => (expanded ? performSearch(value) : setExpanded(true))}
          type="button"
        >
          <SearchIcon color={expanded ? '#999' : '#000'} />
        </button>
      </div>
    </div>
  )
}

const Close = ({ onClick, className = 'w-4 h-4', color = '#999' }) => (
  <button onClick={onClick} type="button">
    <X className={className} color={color} />
  </button>
)

export default Search
