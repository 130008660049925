import classNames from 'classnames'
import { FormContext } from 'components/controls/Form'
import Dropdown from 'components/Dropdown/Dropdown'
import ErrorBoundary from 'components/ErrorBoundary'
import useClickOutside from 'lib/useClickOutside'
import { isEqual, last, startCase } from 'lodash'
import { useContext } from 'react'
import { Check, ChevronDown, X } from 'react-feather'
import { useIntl } from 'react-intl'

export const ResetFilters = ({ onClick }) => {
  const { formatMessage } = useIntl()

  return (
    <button
      className="flex items-center rounded text-sm bg-gray hover:bg-darkGray p-2"
      onClick={onClick}
    >
      {formatMessage({ id: 'term.reset' })}
      <X className="ml-2" size={16} />
    </button>
  )
}

const fieldLabel = (formId, name) => {
  const shortName = last(name.split('.'))

  return [`form.${formId}.${shortName}`, `form.${shortName}`]
}

const Trigger = ({ name, option, onClick }) => {
  // @TODO: DRY up logic repeated in Field as well
  const { formatMessage, messages } = useIntl()
  const { id: formId } = useContext(FormContext) || { id: undefined }
  const [messageId, shortMessageId] = fieldLabel(formId, name)

  const labelText = formatMessage({
    id: messages[messageId] ? messageId : shortMessageId,
    defaultMessage: startCase(name),
  })

  return (
    <button
      type="button"
      className="flex items-center rounded text-sm bg-lightGray hover:bg-gray p-2"
      onClick={onClick}
    >
      {labelText || name} {': '}
      {option ? (
        <OptionName option={option} />
      ) : (
        `${formatMessage({ id: 'term.all' }).toLowerCase()}`
      )}
      <ChevronDown className="ml-2" size={16} />
    </button>
  )
}

const Menu = ({ close, onSelectClick, options, selection }) => {
  const ref = useClickOutside(close)

  return (
    <div
      ref={ref}
      className="absolute bg-white rounded border-darkGray border mt-1 min-w-full z-10"
    >
      {options.map((option) => {
        return (
          <MenuOption
            key={option.id}
            option={option}
            onSelectClick={onSelectClick}
            selected={isEqual(option.id, selection)}
            close={close}
          />
        )
      })}
    </div>
  )
}

const OptionName = ({ option }) => {
  const { formatMessage } = useIntl()

  if (option.name) {
    return option.name
  }

  if (option.nameId) {
    return formatMessage({ id: option.nameId })
  }

  return 'None'
}

const MenuOption = ({ close, onSelectClick, option, selected }) => {
  const onClick = () => {
    if (selected) {
      onSelectClick(null)
    } else {
      onSelectClick(option.id)
    }
    close()
  }

  return (
    <button
      key={option.id}
      className="flex items-center text-left text-sm pl-2 pr-6 py-2 w-full whitespace-nowrap hover:bg-gray focus:outline-none"
      onClick={onClick}
    >
      <div className="w-5">{selected ? <Check size={16} /> : null}</div>
      <OptionName option={option} />
    </button>
  )
}

const Filters = ({
  name,
  options,
  selection,
  setSelection,
  setPage,
  className,
}) => {
  if (options.length === 0) {
    return null
  }

  const onSelectClick = (id) => {
    setPage && setPage(1)
    setSelection(id)
  }

  const option = options.find((o) => isEqual(o.id, selection))

  return (
    <ErrorBoundary>
      <Dropdown
        className={classNames('mr-4', className)}
        trigger={({ toggle }) => (
          <Trigger name={name} option={option} onClick={toggle} />
        )}
      >
        {({ close }) => (
          <Menu
            options={options}
            close={close}
            onSelectClick={onSelectClick}
            selection={selection}
          />
        )}
      </Dropdown>
    </ErrorBoundary>
  )
}

export default Filters
