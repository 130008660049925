import Alert from 'components/feedback/Alert'
import { Form as FormikForm, Formik } from 'formik'
import { startCase } from 'lodash'
import PropTypes from 'prop-types'
import { createContext, useEffect, useRef } from 'react'
import { useIntl } from 'react-intl'

export const FormContext = createContext()

const Errors = (props) => {
  const alertRef = useRef()
  const { formatMessage } = useIntl()

  const errors = Object.keys(props.errors)
  const hasErrors = errors.length > 0

  useEffect(() => {
    if (hasErrors) {
      alertRef.current.scrollIntoView()
    }
  }, [hasErrors])

  return (
    <div ref={alertRef}>
      <div className={hasErrors ? 'block py-4' : 'hidden'}>
        {hasErrors && (
          <Alert type="error" className="mb-4" showIcon>
            {formatMessage({ id: 'util.requiredMulti' })}
            <ul className="list-disc pl-6">
              {errors.map((e) => (
                <li key={e}>{startCase(e)}</li>
              ))}
            </ul>
          </Alert>
        )}
      </div>
    </div>
  )
}

export const Form = ({
  children,
  className,
  id,
  validationSchema,
  showErrorSummary = false,
  ...props
}) => {
  // @TODO: add {dirty} to destructured props
  // and prevent page leave if dirty
  return (
    <FormContext.Provider value={{ id, validationSchema }}>
      <Formik {...props} validationSchema={validationSchema}>
        {({ handleSubmit, ...props }) => {
          // @TODO: might make more sense to show errors before submit button?
          return (
            <FormikForm className={className} onSubmit={handleSubmit}>
              {showErrorSummary && <Errors errors={props.errors} />}
              {children(props)}
            </FormikForm>
          )
        }}
      </Formik>
    </FormContext.Provider>
  )
}

Form.propTypes = {
  className: PropTypes.string,
  chidren: PropTypes.node,
  id: PropTypes.string,
}

export default Form
