import { useQuery } from '@apollo/client'
import classNames from 'classnames'
import { ButtonLink } from 'components/Button'
import Form from 'components/controls/Form'
import MemberCard from 'components/display/MemberCard'
import { getFeatureFlag } from 'components/Feature'
import { FullSpinner } from 'components/feedback/Spinner'
import Filters, { ResetFilters } from 'components/Filters/Filters'
import Pagination from 'components/navigation/Pagination'
import Search from 'components/Search/Search'
import { disciplinesQuery } from 'lib/queries'
import {
  groupFilters,
  groupFrontpageTaggedMentors,
  groupSearchMentors,
} from 'lib/queries/group.gql'
import { memberUrl } from 'lib/urls'
import { useCurrentUser } from 'lib/UserContext'
import Link from 'next/link'
import { useRouter, withRouter } from 'next/router'
import { useIntl } from 'react-intl'
import { NumberParam, StringParam, useQueryParam } from 'use-query-params'

// @TODO: move out to general components
// as this should be used by both Groups and the new marketplace
// if { group } is undefined, render for regular marketplace

// @TODO: this list could be six things:
// A) group members (style page and query group)
//    #1: mentors and mentees
//    #2: mentors
//    #3: mentees
// B) non-group marketplace members (no style, no group query)
//    #4: mentors and mentees
//    #5: mentors
//    #6: mentees

// @TODO: consider renaming to Users -- currently there's User and Member being used
// Member has the connotation of belonging to Group
// User feels more like CurrentUser, but perhaps is better than Member
// a User can be a mentor or a mentee, can be part of a group or marketplace

const Members = ({ group, frontPage }) => {
  const { push } = useRouter()
  const { formatMessage, locale } = useIntl()
  const [query, setQuery] = useQueryParam('query', StringParam)
  const [page, setPage] = useQueryParam('page', NumberParam)
  const limit = 20
  const [discipline, setDiscipline] = useQueryParam('discipline', StringParam)
  const [subdiscipline, setSubdiscipline] = useQueryParam(
    'subdiscipline',
    StringParam
  )
  const [experience, setExperience] = useQueryParam('experience', StringParam)

  const years = formatMessage({ id: 'term.years' })

  const experiences = [
    { id: '5-99', name: `5+ ${years}` },
    { id: '10-99', name: `10+ ${years}` },
    { id: '15-99', name: `15+ ${years}` },
    { id: '20-99', name: `20+ ${years}` },
  ]

  const filtersQuery = group ? groupFilters : disciplinesQuery
  const { loading, error, data } = useQuery(filtersQuery, {
    skip: !group,
    variables: { id: group.id, locale: locale },
  })
  // @TODO: replace group prop with currentGroup. styles is not available otherwise.
  if (loading) return <FullSpinner color={group?.styles?.backgroundTextColor} />
  if (error) return console.error(error)
  if (!data) return console.error('no data')

  const filteredGroup = { ...group, ...data.group, experiences }

  const showResetFilters = discipline || subdiscipline || experience

  const resetFilters = () => {
    setSubdiscipline(undefined)
    setDiscipline(undefined)
    setExperience(undefined)
  }

  if (!group) return false // @TODO: we'll want to show non-group mentors someday

  const memberTypePlural = formatMessage({
    id: 'term.mentors',
  }).toLocaleLowerCase()
  const memberType = formatMessage({ id: 'term.mentor' }).toLocaleLowerCase()

  const browseAllTerm = formatMessage(
    { id: 'button.browseAllTerm' },
    { term: memberTypePlural }
  )
  const browseAll = formatMessage({ id: 'button.browseAll' })
  const filterThe = formatMessage({ id: 'tooltip.filterThe' })
  const term = formatMessage({ id: 'term.a' })
  const searchFor = formatMessage({ id: 'tooltip.searchFor' }, { term: term })

  const subdisciplines = filteredGroup?.disciplines?.find(
    (x) => x.id === discipline
  )?.subdisciplines

  const initialValues = {
    query,
    discipline,
    subdiscipline,
    experience,
  }

  const handleSearch = () => {
    frontPage &&
      query &&
      push({
        pathname: `/${locale}/mentors`,
        query: { query },
      })
  }

  return (
    <section className="pt-12 pb-6 sm:pt-16 sm:pb-2 wrapper fillHeight bg-backgroundColor text-backgroundTextColor">
      <Form
        id="memberSearch"
        initialValues={initialValues}
        onSubmit={handleSearch}
      >
        {() => (
          <>
            <div className="container mx-auto flex flex-col sm:flex-row items-center justify-center sm:justify-between space-y-8 sm:space-x-6 sm:space-y-0">
              <div className="flex-1 w-full sm:w-8/12 md:w-auto xl:w-8/12 flex flex-col lg:flex-row space-y-0 items-start">
                <div className="w-auto sm:mr-4">
                  <p className="font-semibold">
                    {searchFor} {memberType}
                  </p>
                  <p className="text-sm">
                    {formatMessage({ id: 'tooltip.searchBy' })}
                  </p>
                </div>
                <div className="flex-1 w-full">
                  <Search
                    onChange={setQuery}
                    setQuery={setQuery}
                    setPage={setPage}
                    query={query}
                  />
                </div>
              </div>
              <div className="flex-0 w-full sm:w-4/12 md:w-auto xl:w-4/12 flex justify-end flex-col lg:flex-row space-y-0 items-start">
                <div className="w-auto sm:mr-4">
                  <p className="font-semibold">{browseAllTerm}</p>
                  <p className="text-sm">
                    {formatMessage({ id: 'tooltip.browseBy' })}
                  </p>
                </div>
                <div className="flex-1 lg:w-4/5">
                  <Link href={`/${locale}${memberUrl(group)}`} passHref>
                    <ButtonLink full>{browseAll}</ButtonLink>
                  </Link>
                </div>
              </div>
            </div>
            {!frontPage && (
              <div
                className={classNames(
                  'container mx-auto flex mt-6',
                  'flex-col items-start justify-center space-y-0 space-x-0',
                  'lg:flex-row lg:items-center lg:justify-between lg:space-y-0 lg:space-x-6'
                )}
              >
                <div className="w-auto sm:mr-4">
                  <p className="font-semibold">
                    {filterThe} {memberTypePlural}
                  </p>
                  <p className="text-sm">
                    {formatMessage({ id: 'tooltip.browseBy' })}
                  </p>
                </div>
                <div className="flex-1 flex flex-wrap lg:w-4/5 text-black justify-start items-center">
                  <Filters
                    className="pb-2"
                    name="disciplines"
                    options={filteredGroup.disciplines}
                    selection={discipline || ''}
                    setSelection={setDiscipline}
                    setPage={setPage}
                  />

                  {getFeatureFlag(group, 'userSubdisciplines') && (
                    <Filters
                      className="pb-2"
                      name="subdiscipline"
                      options={
                        !subdisciplines
                          ? [
                              {
                                id: null,
                                name: formatMessage({
                                  id: 'tooltip.selectAnOption',
                                }),
                              },
                            ]
                          : subdisciplines.length > 0
                          ? subdisciplines
                          : [
                              {
                                id: null,
                                name: formatMessage({
                                  id: 'tooltip.noOptionsForSelection',
                                }),
                              },
                            ]
                      }
                      selection={subdiscipline}
                      setSelection={setSubdiscipline}
                      setPage={setPage}
                    />
                  )}
                  {getFeatureFlag(group, 'userExperience') && (
                    <Filters
                      className="pb-2"
                      name="experience"
                      options={experiences}
                      selection={experience || ''}
                      setSelection={setExperience}
                      setPage={setPage}
                    />
                  )}
                  {showResetFilters && (
                    <div>
                      <ResetFilters onClick={resetFilters} />
                    </div>
                  )}
                </div>
              </div>
            )}
          </>
        )}
      </Form>
      <MemberList
        group={filteredGroup}
        locale={locale}
        query={query}
        discipline={discipline}
        subdiscipline={subdiscipline}
        experience={experience}
        page={page}
        setPage={setPage}
        limit={limit}
        frontPage={frontPage}
      />
    </section>
  )
}

const MemberList = ({
  discipline,
  experience,
  group,
  page,
  setPage,
  frontPage,
  limit,
  locale,
  query,
  subdiscipline,
}) => {
  const { formatMessage } = useIntl()
  const { currentUser } = useCurrentUser()

  const browseAllTerm = formatMessage(
    { id: 'button.browseAllTerm' },
    {
      term: formatMessage({
        id: 'term.mentors',
      }).toLocaleLowerCase(),
    }
  )

  const tagKeys = currentUser ? currentUser.tags.map((i) => i.key) : ''

  const filters = {
    disciplineId: discipline,
    experience,
    query,
    page,
    limit,
    frontPage,
    subdisciplineId: subdiscipline,
    tagKeys,
  }

  for (var prop in filters) {
    if ([null, undefined, ''].includes(filters[prop])) {
      delete filters[prop]
    }
  }

  const { loading, error, data } = useQuery(groupSearchMentors, {
    variables: {
      groupId: group.id,
      locale,
      ...filters,
    },
    skip: !group.id,
  })

  const {
    loading: tagLoading,
    error: tagError,
    data: taggedMentorsData,
  } = useQuery(groupFrontpageTaggedMentors, {
    variables: {
      groupId: group.id,
      locale,
      ...filters,
    },
    skip: !group.id,
  })

  if (loading || tagLoading)
    return (
      <FullSpinner
        color={group?.styles.backgroundTextColor}
        className="justify-start"
      />
    )

  if (error || tagError) {
    console.error(error)
    return <div>{formatMessage({ id: 'text.searchAgain' })}</div>
  }

  const groupHasMentors = data?.group?.mentors?.length > 0
  const userHasTags = currentUser?.tags?.length > 0

  if (!groupHasMentors && !userHasTags) {
    return (
      <div className="container mx-auto h-32 flex flex-1 items-center w-100 text-center text-lg">
        {formatMessage({ id: 'faq.noResults' })}
      </div>
    )
  }

  const mentors =
    !groupHasMentors && userHasTags
      ? taggedMentorsData.group.mentors
      : data.group.mentors
  const mentorCount =
    !groupHasMentors && userHasTags
      ? taggedMentorsData.group.mentorCount
      : data.group.mentorCount

  return (
    <>
      <div className="container mx-auto grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-x-4 gap-y-8 mt-16">
        {mentors.map((mentor) => (
          <MemberCard key={mentor.id} user={mentor} />
        ))}
      </div>
      {frontPage && mentorCount > limit && (
        <div className="text-center p-6 mt-10">
          <Link href={`/${locale}${memberUrl(group)}`} passHref>
            <ButtonLink>{browseAllTerm}</ButtonLink>
          </Link>
        </div>
      )}
      {!frontPage && (
        <div className="text-center p-6 mt-6 w-full">
          <Pagination
            page={page || 1}
            per={limit}
            setPage={setPage}
            total={mentorCount}
          />
        </div>
      )}
    </>
  )
}

export default withRouter(Members)
