import classNames from 'classnames'
import MentorBadge from 'components/display/MentorBadge'
import { useCurrentGroup } from 'lib/GroupContext'

const ProfilePicture = ({ user, className, asAvatar }) => {
  const { currentGroup } = useCurrentGroup()

  return (
    <div className={classNames('relative', className)}>
      <div
        className={classNames(
          'relative pb-full',
          asAvatar && 'bg-white overflow-hidden rounded-full shadow-sm',
          asAvatar && (user.mentor ? 'border-4 border-yellow' : 'border-gray')
        )}
      >
        <img
          alt={user.name}
          src={user.profileImageUrl}
          className="absolute h-full w-full object-cover"
          onError={(e) => {
            e.target.onError = null
            e.target.src = defaultImage(currentGroup)
          }}
        />
      </div>
      {user.mentor && (
        <MentorBadge
          className="absolute right-0 bottom-0 w-1/6 h-1/6 mx-1/12"
          avatar
        />
      )}
    </div>
  )
}

const defaultImage = (group) => {
  const defaultImage = group.files.find((f) => f.type === 'defaultProfileImage')

  if (defaultImage) {
    return defaultImage.fileUrl
  } else {
    return '//s3.amazonaws.com/co.mentorly.prod/defaults/user/default-placeholder-300x300.png'
  }
}

export default ProfilePicture
