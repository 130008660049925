import classNames from 'classnames'
import { contrastBW } from 'lib/color'
import PropTypes from 'prop-types'

const Pill = ({ children, color, className, fontSize = 'xs' }) => {
  const bgColor = color ? `bg-${color}` : 'bg-yellow'
  const fontColor = `text-${contrastBW(color)}`
  const fontClass = `text-${fontSize} ${fontColor}`

  // bg-invited bg-signedUp bg-pending bg-booked bg-available bg-completedProfile

  return (
    <div
      className={classNames(
        'mr-2 inline-block rounded-full px-2 focus:outline-none whitespace-nowrap',
        bgColor,
        fontClass,
        className
      )}
    >
      {children}
    </div>
  )
}

Pill.defaultProps = {
  color: 'yellow',
}

Pill.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  color: PropTypes.string,
}

export default Pill
