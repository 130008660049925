import classNames from 'classnames'

const fullTypes = ['email', 'password', 'search', 'text']

export const Input = ({ border, className, value, type, prefix, ...props }) => {
  return (
    <div className="flex items-center">
      {prefix && (
        <div className="py-2 px-3 rounded-l bg-gray border border-r-0 border-darkGray">
          {prefix}
        </div>
      )}
      <input
        type={type}
        value={value || ''}
        className={classNames(
          'block py-2 px-3  rounded  text-black placeholder-darkGray',
          className,
          {
            'rounded-l-none': prefix,
            'border border-darkGray': border,
            'w-full': fullTypes.includes(type) || !type,
          }
        )}
        {...props}
      />
    </div>
  )
}

export default Input
