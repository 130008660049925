import classNames from 'classnames'
import { useEffect, useState } from 'react'
import {
  ChevronLeft as LeftIcon,
  ChevronRight as RightIcon,
} from 'react-feather'

import formatPagesIntoArray from './utils/formatPagesIntoArray'

export type IProps = {
  className?: string
  page: number
  per: number
  setPage: (number: number) => void
  total: number
}

export type Page = {
  selected?: boolean
  threeDots?: boolean
  pageNumber: number
}

const Pagination: React.FC<IProps> = ({
  className,
  page,
  per,
  setPage,
  total,
}) => {
  const [formattedPages, setFormattedPages] = useState<Page[]>([])

  const totalPages = Math.ceil(total / per)

  useEffect(() => {
    setFormattedPages(formatPagesIntoArray(page - 1, totalPages))
  }, [page, totalPages])

  if (totalPages <= 1) {
    return null
  }

  return (
    <div
      className={classNames(
        'flex justify-center m-4 p-4 text-evenDarkerGray',
        className
      )}
    >
      {Boolean(page) && (
        <button
          className={classNames(
            'py-1 px-2 mr-3 rounded border border-darkGray text-sm',
            page < 2 && 'cursor-default'
          )}
          onClick={() => setPage(page - 1)}
          disabled={page < 2}
        >
          <LeftIcon
            size={16}
            className={page < 2 ? 'opacity-50' : 'opacity-100'}
          />
        </button>
      )}

      {formattedPages.map(({ pageNumber, selected, threeDots }) => (
        <button
          className={classNames(
            'py-1 px-3 rounded text-sm',
            page >= totalPages && 'cursor-default',
            selected && 'bg-pending',
            selected && 'text-white'
          )}
          key={pageNumber}
          onClick={() => setPage(pageNumber + 1)}
        >
          {threeDots ? '...' : pageNumber + 1}
        </button>
      ))}
      <button
        className={classNames(
          'py-1 px-2 ml-3 rounded border border-darkGray text-sm',
          page >= totalPages && 'cursor-default'
        )}
        onClick={() => setPage(page + 1)}
        disabled={page >= totalPages - 1}
      >
        <RightIcon
          size={16}
          className={page >= totalPages - 1 ? 'opacity-50' : 'opacity-100'}
        />
      </button>
    </div>
  )
}

export default Pagination
