import * as Sentry from '@sentry/browser'
import { Component } from 'react'

class ErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = {
      error: null,
      hasError: false,
    }
  }

  static getDerivedStateFromError(error) {
    return {
      error,
      hasError: true,
    }
  }

  componentDidCatch(error, errorInfo) {
    console.warn(this.props.props)

    Sentry.withScope((scope) => {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key])
      })

      Sentry.captureException(error)
    })
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="p-2 rounded bg-red text-white">An error occured</div>
      )
    }

    return this.props.children
  }
}

export default ErrorBoundary
